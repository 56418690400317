import './App.css';
import {Route, Routes} from "react-router-dom";
import Main from "./screens/main/MainScreen";
import {
    PATH_MAIN
} from "./constants/Paths";

import {useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";


function App() {
    const {webApp} = useTelegram()

    useEffect(() => {
        webApp.ready();
        webApp.expand();

    }, [webApp]);

    return (
        <div className="App">
            <Routes>
                <Route index element={<Main/>}/>
                <Route path={PATH_MAIN} element={<Main/>}/>
                {/*<Route path={PATH_TASKS} element={<TasksPage/>}/>*/}
                {/*<Route path={PATH_EARNED} element={<Earnings/>}/>*/}
                {/*<Route path={PATH_REDEEM} element={<Claim/>}/>*/}

            </Routes>
        </div>
    );
}

export default App;
